<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4 text--primary"
        ><p v-html="message"></p
      ></v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
        <v-btn :color="options.confirmButtonColor" text @click.native="agree">{{
          options.confirmButtonLabel
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
import messages from "@/common/data/messages.data";

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {},
  }),
  
  methods: {
    open(condition, messageKey) {
      if (
        condition === null ||
        condition === undefined ||
        (condition && !!condition)
      ) {
        this.options = {
          confirmButtonLabel: "Confirmar",
          confirmButtonColor: "primary darken-1",
          color: "primary",
          width: 400,
          zIndex: 200,
        };

        let [resource, action] = messageKey.split(".");

        let config = messages[resource][action];

        this.dialog = true;
        this.title = config.title;
        this.message = config.message;

        if (config.options.confirmButtonLabel) {
          this.options.confirmButtonLabel = config.options.confirmButtonLabel;
        }

        if (config.options.confirmButtonColor) {
          this.options.confirmButtonColor = config.options.confirmButtonColor;
        }

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      }
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>