const data = {
    accessKey: {
        add: {
            title: 'Adicionar chave de acesso',
            message: 'Confirma a criação de nova chave de acesso?',
            options: {
                confirmButtonLabel: 'Adicionar'
            }
        },
        revoke: {
            title: 'Revogar chave de acesso',
            message: 'Confirma a revogação da chave de acesso?',
            options: {
                confirmButtonLabel: 'Revogar',
                confirmButtonColor: 'red'
            }
        }
    },
    nora: {
        syncSubscriptionPlans: {
            title: 'Planos de assinatura',
            message: `Confirma a sincronização de planos de assinatura com o Nora?<br/><br/>
                O processo pode levar alguns segundos.`,
            options: {
                confirmButtonLabel: 'Sincronizar'
            }
        },
        syncSubscribers: {
            title: 'Assinantes',
            message: `Confirma a sincronização de assinantes com o Nora?<br/><br/>
                O processo pode levar alguns minutos.`,
            options: {
                confirmButtonLabel: 'Sincronizar'
            }
        },
    },
    package: {
        add: {
            title: 'Adicionar pacote',
            message: 'Confirma a adição do pacote?',
            options: {
                confirmButtonLabel: 'Adicionar'
            }
        },
        update: {
            title: 'Alterar pacote',
            message: 'Confirma a alteração do pacote?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        remove: {
            title: 'Excluir pacote',
            message: 'Confirma a exclusão do pacote?',
            options: {
                confirmButtonLabel: 'Excluir',
                confirmButtonColor: 'red'
            }
        }
    },
    profile: {
        add: {
            title: 'Adicionar perfil',
            message: 'Confirma a adição do perfil?',
            options: {
                confirmButtonLabel: 'Adicionar'
            }
        },
        update: {
            title: 'Alterar perfil',
            message: 'Confirma a alteração do perfil?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        remove: {
            title: 'Excluir perfil',
            message: 'Confirma a exclusão do perfil?',
            options: {
                confirmButtonLabel: 'Excluir',
                confirmButtonColor: 'red'
            }
        }
    },
    provider: {
        add: {
            title: 'Adicionar provedor',
            message: 'Confirma a adição do provedor?',
            options: {
                confirmButtonLabel: 'Adicionar'
            }
        },
        update: {
            title: 'Alterar provedor',
            message: 'Confirma a alteração do provedor?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        remove: {
            title: 'Excluir provedor',
            message: 'Confirma a exclusão do provedor?',
            options: {
                confirmButtonLabel: 'Excluir',
                confirmButtonColor: 'red'
            }
        }
    },
    streamingDevice: {
        releaseIp: {
            title: 'Liberar IP',
            message: 'Confirma a liberação do IP?',
            options: {
                confirmButtonLabel: 'Liberar'
            }
        },
        update: {
            title: 'Altearar dispositivo',
            message: 'Confirma a alteração do dispositivo?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        remove: {
            title: 'Excluir dispositivo',
            message: 'Confirma a exclusão do dispositivo?',
            options: {
                confirmButtonLabel: 'Excluir',
                confirmButtonColor: 'red'
            }
        }
    },
    subscriber: {
        add: {
            title: 'Adicionar assinante',
            message: 'Confirma a adição do assinante?',
            options: {
                confirmButtonLabel: 'Adicionar'
            }
        },
        update: {
            title: 'Alterar assinante',
            message: 'Confirma a alteração do assinante?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        remove: {
            title: 'Excluir assinante',
            message: 'Confirma a exclusão do assinante?<br/><br/><strong>Todos os dados históricos serão removidos da plataforma!</strong>',
            options: {
                confirmButtonLabel: 'Excluir',
                confirmButtonColor: 'red'
            }
        },
        exportExcel: {
            title: 'Exportar assinantes',
            message: 'Confirma a exportação de assinantes no formato Excel?',
            options: {
                confirmButtonLabel: 'Exportar'
            }
        },
        exportCsv: {
            title: 'Exportar assinantes',
            message: 'Confirma a exportação de assinantes no formato CSV?',
            options: {
                confirmButtonLabel: 'Exportar'
            }
        },
        exportJson: {
            title: 'Exportar assinantes',
            message: 'Confirma a exportação de assinantes no formato JSON?',
            options: {
                confirmButtonLabel: 'Exportar'
            }
        }
    },
    subscription: {
        add: {
            title: 'Ativar assinatura',
            message: 'Confirma a ativação da assinatura?<br>Uma nova cobrança será gerada.',
            options: {
                confirmButtonLabel: 'Ativar'
            }
        },
        update: {
            title: 'Alterar assinatura',
            message: 'Confirma a alteração da assinatura?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        enable: {
            title: 'Ativar assinatura',
            message: 'Confirma a ativação da assinatura?',
            options: {
                confirmButtonLabel: 'Ativar'
            }
        },
        disable: {
            title: 'Cancelar assinatura',
            message: 'Confirma o cancelamento da assinatura?',
            options: {
                confirmButtonLabel: 'Confirmar',
                confirmButtonColor: 'red'
            }
        }
    },
    subscriptionPlan: {
        add: {
            title: 'Adicionar plano de assinatura',
            message: 'Confirma a adição do plano de assinatura?',
            options: {
                confirmButtonLabel: 'Adicionar'
            }
        },
        update: {
            title: 'Alterar plano de assinatura',
            message: 'Confirma a alteração do plano de assinatura?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        remove: {
            title: 'Excluir plano de assinatura',
            message: 'Confirma a exclusão do plano de assinatura?',
            options: {
                confirmButtonLabel: 'Excluir',
                confirmButtonColor: 'red'
            }
        }
    },
    sync: {
        request: {
            title: 'Sincronização Nora',
            message: 'Deseja importar os dados do Nora?',
            options: {
                confirmButtonLabel: 'Sincronizar'
            }
        },
        syncSubscriberDevices: {
            title: 'Sincronização Nora',
            message: 'Deseja sincronizaro os dispositivos do assinante?',
            options: {
                confirmButtonLabel: 'Sincronizar'
            }
        }
    },
    user: {
        add: {
            title: 'Adicionar usuário',
            message: 'Confirma a adição do usuário?',
            options: {
                confirmButtonLabel: 'Adicionar'
            }
        },
        update: {
            title: 'Alterar usuário',
            message: 'Confirma a alteração do usuário?',
            options: {
                confirmButtonLabel: 'Alterar'
            }
        },
        remove: {
            title: 'Excluir usuário',
            message: 'Confirma a exclusão do usuário?',
            options: {
                confirmButtonLabel: 'Excluir',
                confirmButtonColor: 'red'
            }
        },
        requestPasswordReset: {
            title: 'Redefinir senha',
            message: 'Deseja solicitar redefinição de senha?',
            options: {
                confirmButtonLabel: 'Redefinir senha'
            }
        },
        logout: {
            title: 'Sair',
            message: 'Deseja sair da aplicação?',
            options: {
                confirmButtonLabel: 'Sair'
            }
        }
    }
}

export default { ...data };