import Vue from 'vue';

Vue.filter('cpfCnpj', (value) => {
    let formatted = '',
        firstPart,
        secondPart,
        thirdPart,
        fourthPart,
        digit;

    if (value) {
        value = ('' + value).replace(/\D/g, '');

        if (value.length <= 11) {
            firstPart = value.substr(0, 3);

            formatted = firstPart;
            if (value.length > 3) { formatted += '.'; }
           
            secondPart = value.substr(3, 3);
            formatted += secondPart;
            if (value.length > 6) { formatted += '.'; }

            thirdPart = value.substr(6, 3);
            formatted += thirdPart;
            if (value.length > 9) { formatted += '-'; }

            digit = value.substr(9, 2);
            formatted += digit;
        } else { // cnpj
            firstPart = value.substr(0, 2);

            formatted = firstPart;
            if (value.length > 2) { formatted += '.'; }

            secondPart = value.substr(2, 3);
            formatted += secondPart;
            if (value.length > 5) { formatted += '.'; }

            thirdPart = value.substr(5, 3);
            formatted += thirdPart;
            if (value.length > 8) { formatted += '/'; }

            fourthPart = value.substr(8, 4);
            formatted += fourthPart;
            if (value.length >= 12) { formatted += '-'; }

            digit = value.substr(12, 2);
            formatted += digit;
        }
    }

    return formatted;
});