import userApi from '@/common/api/user.api';

const hasPermission = (resources: string | string[]): boolean => {
    const user = userApi.getCurrentUser();
    let allowAccess = false;

    if (user) {
        let permissions: string[] = [];

        if (typeof resources === 'string') {
            permissions.push(resources);
        } else {
            permissions = resources;
        }

        allowAccess = user.permissions && !!user.permissions.find((permission: string) => permissions.indexOf(permission) >= 0);
    }

    return allowAccess;
};

export default {
    hasPermission
}