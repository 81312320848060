<template>
  <v-app>
    <router-view></router-view>

    <Confirm ref="confirm"></Confirm>
    <Toaster></Toaster>
  </v-app>
</template>

<script>
import Confirm from "@/components/layout/Confirm";
import Toaster from "@/components/layout/Toaster";
import dotenv from "dotenv";

dotenv.config();

export default {
  name: "App",

  components: {
    Confirm, Toaster
  },

  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
};
</script>
