import { eventHub } from '@/main';

export default {
    show: (message) => {
        eventHub.$emit('TOASTER', message, 'success', true);

    },
    error: (error) => {
        eventHub.$emit('TOASTER', error, 'error', false);
    }
}