import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Validation from "@/plugins/validation";
import VueMask from 'v-mask'
import vueDebounce from 'vue-debounce'
import VueClipboard from 'vue-clipboard2'

import './assets/scss/main.scss';
import '@/common/directives';
import '@/common/filters';

Vue.use(VueMask);
Vue.use(vueDebounce);
Vue.use(VueClipboard)
Vue.use(Validation);

export const eventHub = new Vue();

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
