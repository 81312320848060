import config from '@/config/config';

export default {
    endpoints: {
        baseUrl: config.apiURL,

        defaults: {
            headers: {
                'Content-Type': 'application/json'
            }
        },

        accessKey: {
            list: {
                url: '/users/:userId/access-keys',
                method: 'GET'
            },
            add: {
                url: '/users/:userId/access-keys',
                method: 'POST'
            },
            revoke: {
                url: '/users/:userId/access-keys/:accessKeyId',
                method: 'DELETE'
            }
        },

        address: {
            lookup: {
                url: '/addresses',
                method: 'GET'
            }
        },

        billing: {
            list: {
                url: '/providers/:providerId/billings',
                method: 'GET'
            }
        },

        client: {
            list: {
                url: '/clients',
                method: 'GET'
            }
        },

        log: {
            list: {
                url: '/logs',
                method: 'GET'
            },
            markAsSolved: {
                url: '/logs',
                method: 'PUT'
            }
        },

        nora: {
            syncSubscriptionPlans: {
                url: '/nora/sync/subscription-plans',
                method: 'POST'
            },
            syncSubscribers: {
                url: '/nora/sync/subscribers',
                method: 'POST'
            }
        },

        profile: {
            list: {
                url: '/profiles',
                method: 'GET'
            },
            listPermissions: {
                url: '/permissions',
                method: 'GET'
            },
            get: {
                url: '/profiles/:profileId',
                method: 'GET'
            },
            add: {
                url: '/profiles',
                method: 'POST'
            },
            update: {
                url: '/profiles/:profileId',
                method: 'PUT'
            },
            remove: {
                url: '/profiles/:profileId',
                method: 'DELETE'
            }
        },

        provider: {
            list: {
                url: '/providers',
                method: 'GET'
            },
            listServices: {
                url: '/providers/services',
                method: 'GET'
            },
            get: {
                url: '/providers/:providerId',
                method: 'GET'
            },
            add: {
                url: '/providers',
                method: 'POST'
            },
            update: {
                url: '/providers/:providerId',
                method: 'PUT'
            },
            remove: {
                url: '/providers/:providerId',
                method: 'DELETE'
            }
        },

        streamingDevice: {
            list: {
                url: '/streaming-devices',
                method: 'GET'
            },
            get: {
                url: '/streaming-devices/:deviceId',
                method: 'GET'
            },
            changeStatus: {
                url: '/subscribers/:subscriberId/streaming-devices/:deviceId',
                method: 'PATCH'
            },
            update: {
                url: '/streaming-devices/:deviceId',
                method: 'PUT'
            },
            remove: {
                url: '/streaming-devices/:deviceId',
                method: 'DELETE'
            }
        },

        subscriber: {
            exportExcel: {
                url: '/subscribers/excel',
                method: 'GET',
                options: {
                    responseType: 'blob'
                }
            },
            exportProviderExcel: {
                url: '/providers/:providerId/subscribers/excel',
                method: 'GET',
                options: {
                    responseType: 'blob'
                }
            },
            exportCsv: {
                url: '/subscribers/csv',
                method: 'GET',
                options: {
                    responseType: 'blob'
                }
            },
            exportProviderCsv: {
                url: '/providers/:providerId/subscribers/csv',
                method: 'GET',
                options: {
                    responseType: 'blob'
                }
            },
            exportJson: {
                url: '/subscribers/json',
                method: 'GET',
                options: {
                    responseType: 'blob'
                }
            },
            list: {
                url: '/providers/:providerId/subscribers',
                method: 'GET'
            },
            get: {
                url: '/providers/:providerId/subscribers/:subscriberId',
                method: 'GET'
            },
            add: {
                url: '/providers/:providerId/subscribers',
                method: 'POST'
            },
            update: {
                url: '/providers/:providerId/subscribers/:subscriberId',
                method: 'PUT'
            },
            remove: {
                url: '/providers/:providerId/subscribers/:subscriberId',
                method: 'DELETE'
            }
        },

        subscription: {
            list: {
                url: '/providers/:providerId/subscribers/:subscriberId/subscriptions',
                method: 'GET'
            },
            listChanges: {
                url: '/providers/:providerId/subscriptions/changes',
                method: 'GET'
            },
            add: {
                url: '/providers/:providerId/subscribers/:subscriberId/subscriptions',
                method: 'POST'
            },
            update: {
                url: '/providers/:providerId/subscribers/:subscriberId/subscriptions',
                method: 'PUT'
            },
            changeStatus: {
                url: '/providers/:providerId/subscribers/:subscriberId/subscriptions',
                method: 'PATCH'
            },
            viewPassword: {
                url: '/providers/:providerId/subscribers/:subscriberId/subscriptions/:planProviderId/password',
                method: 'GET'
            }
        },

        subscriptionPlan: {
            list: {
                url: '/providers/:providerId/subscription-plans',
                method: 'GET'
            },
            get: {
                url: '/providers/:providerId/subscription-plans/:subscriptionPlanId',
                method: 'GET'
            },
            add: {
                url: '/providers/:providerId/subscription-plans',
                method: 'POST'
            },
            update: {
                url: '/providers/:providerId/subscription-plans/:subscriptionPlanId',
                method: 'PUT'
            },
            remove: {
                url: '/providers/:providerId/subscription-plans/:subscriptionPlanId',
                method: 'DELETE'
            }
        },

        sync: {
            list: {
                url: '/sync/progress',
                method: 'GET'
            },
            request: {
                url: '/sync',
                method: 'POST'
            },
            syncSubscriberDevices: {
                url: '/sync/:provider_id/subscribers/:subscriber_id/devices',
                method: 'POST'
            }
        },

        user: {
            list: {
                url: '/users',
                method: 'GET'
            },
            get: {
                url: '/users/:userId',
                method: 'GET'
            },
            add: {
                url: '/users',
                method: 'POST'
            },
            update: {
                url: '/users/:userId',
                method: 'PUT'
            },
            remove: {
                url: '/users/:userId',
                method: 'DELETE'
            },

            login: {
                url: '/login',
                method: 'POST',
                supressError: true
            },
            logout: {
                url: '/logout',
                method: 'POST'
            },
            changePassword: {
                url: '/change-password',
                method: 'POST'
            },
            requestPasswordReset: {
                url: '/request-password-reset',
                method: 'POST'
            },
            resetPassword: {
                url: '/reset-password',
                method: 'POST'
            },
            renewToken: {
                url: '/renew-token',
                method: 'POST'
            }
        },

        reports: {
            totalSubscriptions: {
                url: '/reports/total_subscribers',
                method: 'GET'               
            }
        }
    }
}