import Vue from 'vue';
import moment from 'moment'

Vue.filter('fromNow', (value) => {
    if (value) {
        moment.locale('pt');

        return moment(value).fromNow();
    }

    return value;
});