import Vue from 'vue';

Vue.filter('currency', (value, decimals = 2) => {
    let result = '';

    if (value !== undefined && value !== null) {
        let val = (value / 1).toFixed(decimals).replace('.', ',');
        result = 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return result;
});