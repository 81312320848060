<template>
  <v-snackbar
    :color="backgroundColor"
    v-model="showSnackBar"
    :timeout="autoHide ? 3000 : 4000"
  >
    <span v-html="text"></span>
    <template v-slot:action="{ attrs }">
      <v-btn icon text v-bind="attrs" @click="showSnackBar = false">
        <v-icon> mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { eventHub } from "@/main";

export default {
  data: () => ({
    backgroundColor: "success",
    showSnackBar: false,
    autoHide: false,
    text: "",
    type: "success",
  }),

  beforeMount() {
    eventHub.$on("TOASTER", this.displayMessage);
  },

  beforeDestroy() {
    eventHub.$off("TOASTER");
  },

  methods: {
    displayMessage(messages, type, autoHide) {
      let result = "";

      if (messages) {
        if (typeof messages === "string") {
          result = messages;
        } else if (Array.isArray(messages)) {
          result = messages.join("\n");
        }
      }

      this.text = result;
      this.type = type || "success";
      this.backgroundColor = this.type;
      this.autoHide = autoHide;

      if (result) {
        this.showSnackBar = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>