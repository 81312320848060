import Vue from 'vue'
import VueRouter from 'vue-router'
import authApi from '@/common/api/auth.api';

Vue.use(VueRouter)

const validatePermission = (resource, next) => {
    const permission = authApi.hasPermission(resource);

    if (permission) {
        next();
    } else {
        next(false);
    }
}

const routes = [
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "public" */ '@/layouts/Public.vue'),
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
            },
        ]
    },
    {
        path: '/solicitar-nova-senha',
        component: () => import(/* webpackChunkName: "public" */ '@/layouts/Public.vue'),
        children: [
            {
                path: '',
                name: 'RequestPasswordChange',
                component: () => import(/* webpackChunkName: "requestPasswordChange" */ '../views/user/RequestPasswordChange.vue')
            },
        ]
    },
    {
        path: '/redefinir-senha/:token',
        component: () => import(/* webpackChunkName: "public" */ '@/layouts/Public.vue'),
        children: [
            {
                path: '',
                name: 'ResetPassword',
                component: () => import(/* webpackChunkName: "resetPassword" */ '../views/user/ResetPassword.vue')
            },
        ]
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "logged" */ '@/layouts/Backoffice.vue'),
        children: [
            {
                path: '/sync',
                name: 'SyncList',
                component: () => import(/* webpackChunkName: "sync-list" */ '../views/sync/SyncList.vue'),
                beforeEnter: function (to, from, next) {
                    validatePermission('SYNC.LIST', next);
                }
            },
            {
                path: '/provedores',
                name: 'ProviderList',
                component: () => import(/* webpackChunkName: "provider-list" */ '../views/provider/ProviderList.vue'),
                beforeEnter: function (to, from, next) {
                    validatePermission('PROVIDER.LIST', next);
                }
            },
            {
                path: '/provedores/:id',
                name: 'ProviderDetails',
                component: () => import(/* webpackChunkName: "provider-details" */ '../views/provider/ProviderDetails.vue'),
                beforeEnter: function (to, from, next) {
                    validatePermission('PROVIDER.GET', next);
                }
            },
            {
                path: '/reports/total-subscriptions',
                name: 'TotalSubscriptionList',
                component: () => import(/* webpackChunkName: "provider-list" */ '../views/reports/total-subscriptions/TotalSubscriptionList.vue'),
                meta: { clientSelector: { visible: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('PROVIDER.LIST', next);
                }
            },
            {
                path: '/planos-assinatura',
                name: 'SubscriptionPlanList',
                component: () => import(/* webpackChunkName: "subscription-plan-list" */ '../views/subscription-plan/SubscriptionPlanList.vue'),
                meta: { clientSelector: { visible: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('SUBSCRIPTION_PLAN.LIST', next);
                }
            },
            {
                path: '/planos-assinatura/:id',
                name: 'SubscriptionPlanDetails',
                component: () => import(/* webpackChunkName: "subscription-plan-details" */ '../views/subscription-plan/SubscriptionPlanDetails.vue'),
                meta: { clientSelector: { visible: true, disabled: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('SUBSCRIPTION_PLAN.GET', next);
                }
            },
            {
                path: '/assinantes',
                name: 'SubscriberList',
                component: () => import(/* webpackChunkName: "subscriber-list" */ '../views/subscriber/SubscriberList.vue'),
                meta: { clientSelector: { visible: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('SUBSCRIBER.LIST', next);
                }
            },
            {
                path: '/assinantes/:id',
                name: 'SubscriberDetails',
                component: () => import(/* webpackChunkName: "subscriber-details" */ '../views/subscriber/SubscriberDetails.vue'),
                meta: { clientSelector: { visible: true, disabled: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('SUBSCRIBER.GET', next);
                }
            },
            {
                path: '/dispositivos',
                name: 'StreamingDeviceList',
                component: () => import(/* webpackChunkName: "streaming-device-list" */ '../views/streaming-device/StreamingDeviceList.vue'),
                meta: { clientSelector: { visible: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('STREAMING_DEVICE.LIST', next);
                }
            },
            {
                path: '/alteracoes-assinaturas',
                name: 'SubscriptionChangesList',
                component: () => import(/* webpackChunkName: "subscription-change-list" */ '../views/subscription-change/SubscriptionChangesList.vue'),
                meta: { clientSelector: { visible: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('SUBSCRIPTION.LIST_CHANGES', next);
                }
            },
            {
                path: '/dispositivos/:id',
                name: 'StreamingDeviceDetails',
                component: () => import(/* webpackChunkName: "streaming-device-details" */ '../views/streaming-device/StreamingDeviceDetails.vue'),
                meta: { clientSelector: { visible: true, disabled: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('STREAMING_DEVICE.GET', next);
                }
            },
            {
                path: '/perfis',
                name: 'Profiles',
                component: () => import(/* webpackChunkName: "profile-list" */ '../views/profile/ProfileList.vue'),
                beforeEnter: function (to, from, next) {
                    validatePermission('PROFILE.LIST', next);
                }
            },
            {
                path: '/perfis/:id',
                name: 'ProfileDetails',
                component: () => import(/* webpackChunkName: "profile-details" */ '../views/profile/ProfileDetails.vue'),
                beforeEnter: function (to, from, next) {
                    validatePermission('PROFILE.GET', next);
                }
            },
            {
                path: '/admins',
                name: 'Admins',
                component: () => import(/* webpackChunkName: "admin-list" */ '../views/user/AdminList.vue'),
                beforeEnter: function (to, from, next) {
                    validatePermission('USER.LIST', next);
                }
            },
            {
                path: '/admins/:id',
                name: 'AdminDetails',
                component: () => import(/* webpackChunkName: "admin-details" */ '../views/user/AdminDetails.vue'),
                meta: { clientSelector: { visible: true, disabled: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('USER.GET', next);
                }
            },
            {
                path: '/usuarios',
                name: 'Users',
                component: () => import(/* webpackChunkName: "user-list" */ '../views/user/UserList.vue'),
                meta: { clientSelector: { visible: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('USER.LIST', next);
                }
            },
            {
                path: '/usuarios/:id',
                name: 'UserDetails',
                component: () => import(/* webpackChunkName: "user-details" */ '../views/user/UserDetails.vue'),
                meta: { clientSelector: { visible: true, disabled: true } },
                beforeEnter: function (to, from, next) {
                    validatePermission('USER.GET', next);
                }
            },
            {
                path: '/alterar-senha',
                name: 'ChangePassword',
                component: () => import(/* webpackChunkName: "changePassword" */ '../views/user/ChangePassword.vue')
            },
            {
                path: '/logs',
                name: 'Logs',
                component: () => import(/* webpackChunkName: "log-list" */ '../views/log/LogList.vue'),
            },
            {
                path: '*',
                redirect: '/login'
            },
        ]
    },
];

const router = new VueRouter({
    routes
});

export default router
