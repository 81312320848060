import Vue from 'vue';

Vue.filter('truncate', (value, maxlength, clamp = '...', ignoreWords = false) => {
    let lengthCount = 0;
    let result = '';

    if (value) {
        if (!ignoreWords) {
            // let words = value.split(/(?:\n|\s)+/);
            let words = value.split(/\s/);

            for (let i = 0; i < words.length; i++) {
                const word = words[i];

                if ((lengthCount + word.length + 1) < maxlength) {
                    result += word + ' ';
                    lengthCount += word.length + 1;
                } else {
                    result += clamp;
                    break;
                }
            }
        } else {
            result = value.length <= maxlength ? value : value.substring(0, maxlength) + clamp;
        }
    }

    return result;
});