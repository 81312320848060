import httpClient from './http-client';

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('user', 'list', params);
};

const get = (userId: string): Promise<any> => {
    const params = {
        query: {
            userId: userId
        }
    };

    return httpClient.invoke('user', 'get', params)
};

const add = (data: any): Promise<any> => {
    const params = {
        data: data
    };

    return httpClient.invoke('user', 'add', params)
};

const update = (userId: string, data: any): Promise<any> => {
    const params = {
        query: {
            userId: userId
        },
        data: data
    };

    return httpClient.invoke('user', 'update', params)
};

const remove = (userId: string): Promise<any> => {
    const params = {
        query: {
            userId: userId
        }
    };

    return httpClient.invoke('user', 'remove', params)
};

const login = (data: any): Promise<any> => {
    const params = {
        data: data
    };

    return httpClient.invoke('user', 'login', params)
        .then(response => {
            setCurrentUser({
                id: response.data.id,
                name: response.data.name,
                username: response.data.username,
                email: response.data.email,
                permissions: response.data.permissions
            });

            return response;
        });
};

const logout = (): void => {
    // let refreshToken = this.authenticationService.getRefreshToken();

    // if (refreshToken) {
    //     var params = {
    //         data: {
    //             refresh_token: refreshToken
    //         }
    //     };

    //     return httpClient.invoke('user', 'logout', params)
    //         .pipe(
    //             tap(() => this.authenticationService.clearRefreshToken())
    //         );
    // } else {
    //     return of(true);
    // }
    clearCurrentUser();
    sessionStorage.clear();
}

const setCurrentUser = (user: any): void => {
    sessionStorage.setItem('CURRENT_USER', JSON.stringify(user));
}

const getCurrentUser = (): any => {
    const json = sessionStorage.getItem('CURRENT_USER');
    let user;

    if (json) {
        user = JSON.parse(json);
    }

    return user;
}

const setUserModule = (value) => {
    sessionStorage.setItem("SELECTED_MODULE", value);
};

const getUserModule = () => {
    return sessionStorage.getItem("SELECTED_MODULE");
};

const clearCurrentUser = (): void => {
    sessionStorage.removeItem('CURRENT_USER');
}

const changePassword = (password: string, newPassword: string): Promise<any> => {
    const params = {
        data: {
            password: password,
            newPassword: newPassword
        }
    };

    return httpClient.invoke('user', 'changePassword', params);
}

const requestPasswordReset = (email: string): Promise<any> => {
    const params = {
        data: {
            email: email
        }
    };

    return httpClient.invoke('user', 'requestPasswordReset', params);
}

const resetPassword = (data: any): Promise<any> => {
    const params = {
        data: data
    };

    return httpClient.invoke('user', 'resetPassword', params);
}

export default {
    list,
    get,
    add,
    update,
    remove,

    login,
    logout,
    setCurrentUser,
    getCurrentUser,
    setUserModule,
    getUserModule,
    clearCurrentUser,
    changePassword,
    requestPasswordReset,
    resetPassword
}