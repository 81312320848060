import Vue from 'vue';
import authApi from "@/common/api/auth.api";

Vue.directive('auth', {
    bind: (el, binding) => {
        let permission = authApi.hasPermission(binding.value);

        if (!permission) {
            el.style.display = 'none';
        }
    },
    update: (newValue, oldValue) => {
        // do something based on the updated value
        // this will also be called for the initial value
        

    },
    unbind: () => {
        // do clean up work
        // e.g. remove event listeners added in bind()
    }
})