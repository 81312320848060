import axios from 'axios';
import router from '@/router'
import config from '@/config/endpoints';
import toaster from '@/common/util/toaster';
import { eventHub } from "@/main";

const axiosClient = axios.create({
    baseURL: config.endpoints.baseUrl,
    headers: config.endpoints.defaults.headers || {}
});

axiosClient.interceptors.request.use(
    config => {
        // token de autorização
        const accessToken = sessionStorage.getItem('ACCESS_TOKEN');

        if (accessToken) {
            config.headers.common["Authorization"] = `Bearer ${accessToken}`;
        }

        // loader
        eventHub.$emit('LOADING', true);
        return config;
    },
    error => {
        // loader
        eventHub.$emit('LOADING', false);
        return Promise.reject(error);
    }
);
axiosClient.interceptors.response.use(
    response => {
        // token de autorização
        if (typeof response.data === 'object' && response.data !== null && response.data.access_token) {
            sessionStorage.setItem('ACCESS_TOKEN', response.data.access_token);
            localStorage.setItem('REFRESH_TOKEN', response.data.refresh_token);
        }

        // loader
        eventHub.$emit('LOADING', false);
        return response;
    },
    error => {
        // loader
        eventHub.$emit('LOADING', false);

        // sessão expirada
        const originalRequest = error.config;

        if (error && error.response && error.response.status === 401 && !originalRequest._retry && router.currentRoute.name !== 'Login') {
            originalRequest._retry = true;
            return refreshAccessToken().then(accessToken => {
                sessionStorage.setItem('ACCESS_TOKEN', accessToken);
                originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                return axiosClient(originalRequest);
            }).catch(() => {
                sessionStorage.removeItem('ACCESS_TOKEN');
                localStorage.removeItem('REFRESH_TOKEN');
                router.push('/login');

                return Promise.reject(error);
            });
        }

        return Promise.reject(error);
    }
);

const refreshAccessToken = () => {
    const token = localStorage.getItem('REFRESH_TOKEN');
    const module_id = sessionStorage.getItem('SELECTED_MODULE');

    if (!token || !module_id) {
        return Promise.reject('Refresh token not found');
    }

    const params = {
        data: {
            refresh_token: token,
            module_id: module_id
        }
    };

    return httpClient().invoke('user', 'renewToken', params).then(result => {
        if (result) {
            return result.data.access_token;
        }

        return null;
    }, _ => {
        sessionStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('REFRESH_TOKEN');
    });
}

const mapParameters = (url, parameters) => {
    const extra: string[] = [];
    let param;
    let newUrl;
    let result = url;

    if (parameters) {
        for (param in parameters) {
            // parâmetros que começam com "_" são usados para salvar estados dos filtros 
            // e devem ser ignorados na chamada do serviço
            if (!param.startsWith('_') && parameters[param] !== undefined && url.indexOf(':' + param) < 0) {
                extra.push(param + '=' + encodeURIComponent(parameters[param]));
            }
        }

        newUrl = url.replace(/:(\w+)/g, (substring, match) => {
            parameters = parameters || {};

            let routeValue = parameters[match];
            if (!routeValue) {
                routeValue = ':' + match;
            }
            return routeValue;
        });

        // if we missed a value completely, then throw again
        if (newUrl.indexOf('/:') > 0) {
            throw 'Sushi: not all route values were matched (' + url + ')';
        }


        // finally attach query string parameters if necessary
        result = (extra.length === 0) ? newUrl : newUrl + '?' + extra.join('&');
    }

    return result;
}

const httpClient = () => {
    const loadEndpointConfig = (category, operation) => {
        const endpointsConfig = JSON.parse(JSON.stringify(config));
        const endpointConfig: any = {};
        let found = false;

        for (const group in endpointsConfig) {
            if (endpointsConfig[group][category] && endpointsConfig[group][category][operation]) {
                found = true;

                endpointConfig.baseUrl = endpointsConfig[group].baseUrl || '';
                endpointConfig.defaults = endpointsConfig[group].defaults || {};
                endpointConfig.endpoint = endpointsConfig[group][category][operation];

                break;
            }
        }

        if (!found) {
            throw 'Endpoint não encontrado para a categoria [' + category + '] e operação [' + operation + ']';
        }

        return endpointConfig;
    };

    const mountUrl = (category, operation, params) => {
        const endpointConfig = loadEndpointConfig(category, operation);
        let url = '';

        if (endpointConfig.baseUrl) {
            url = endpointConfig.baseUrl;
        } else {
            if (endpointConfig.endpoint.baseUrl) {
                url = endpointConfig.endpoint.baseUrl;
            }
        }

        if (endpointConfig.endpoint.url) {
            url += endpointConfig.endpoint.url;
        }

        url = mapParameters(url, params.query);

        return url;
    };

    const invoke = (category, operation, params: any = {}, formData = null) => {
        const endpointConfig = loadEndpointConfig(category, operation);
        const options: any = {};

        const url = mountUrl(category, operation, params);

        // global headers
        options.headers = endpointConfig.defaults.headers || {};

        // endpoint headers
        if (endpointConfig.endpoint.headers) {
            options.headers = Object.assign(options.headers, endpointConfig.endpoint.headers);
        }

        // operation headers
        if (params.headers) {
            options.headers = Object.assign(options.headers, params.headers);
        }

        for (const property in options.headers) {
            if (!options.headers[property]) {
                delete options.headers[property];
            }
        }

        // endpoint options
        if (endpointConfig.endpoint.options) {
            for (const i in endpointConfig.endpoint.options) {
                options[i] = endpointConfig.endpoint.options[i];
            }
        }

        // operation options
        if (params.options) {
            for (const i in params.options) {
                options[i] = params.options[i];
            }
        }

        if (formData) {
            options.data = formData;
        } else if (params.data) {
            options.data = params.data;
        }

        options.method = endpointConfig.endpoint.method;
        options.url = url;

        return axiosClient(options)
            .catch(error => {
                if (!error.response) {
                    toaster.error('Erro de conexão');
                } else {
                    if (error.response.data && (error.response.status !== 401
                        || (error.response.status === 401 && ['ChangePassword', 'Login'].indexOf(router.currentRoute.name || '') >= 0))) {
                        toaster.error(error.response.data._messages);
                    }
                }

                throw error;
            });
    };

    return {
        invoke
    };
}

export default httpClient();