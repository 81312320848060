import Vue from 'vue';

Vue.filter('enum', (value, data, valueProperty, textProperty) => {
    if (!valueProperty) { valueProperty = 'id' }
    if (!textProperty) { textProperty = 'name' }

    let item = data.find(item => item[valueProperty] === value);

    if (item) {
        return item[textProperty];
    }

    return value;
});