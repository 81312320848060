import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { cpf, cnpj } from 'cpf-cnpj-validator';

const isEmpty = (value) => {
    return (value === undefined || value === null || value === '');
};

const rules = {
    required: (value) => !isEmpty(value) || "Campo obrigatório",
    requiredArray: (value) => (value && Array.isArray(value) && value.length) || 'Campo obrigatório',
    number: (digits, message) => {
        return (value) => {
            const regex = '\\d{' + digits + '}';
            return isEmpty(value) || (new RegExp(regex).test(value)) || message || 'Número inválido';
        }
    },
    name: (value) => {
        const pattern = /^[\w ]*$/g;
        return (pattern.test(value) || "Nome possui caracteres inválidos");
    },
    username: (value) => {
        const pattern = /^[\w]*$/g;
        return (pattern.test(value) || "Somente letras e números");
    },
    email: (value) => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return isEmpty(value) || (pattern.test(value) || "E-mail inválido");
    },
    cpf: (value) => isEmpty(value) || cpf.isValid(value) || 'CPF inválido',
    cnpj: (value) => isEmpty(value) || cnpj.isValid(value) || 'CNPJ inválido',
    checkSamePassword: (value1, value2) => {
        return value1 === value2 || "Senha não confere";
    },
};

const masks = {
    // tem bug. nao funcion para um dos tipos
    // cpfCnpj: (value) => {
    //     let a = [];

    //     a = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

    //     if (value && value.length === 14) {
    //         a = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    //     }

    //     return a;
    // },
    percentage: createNumberMask({
        prefix: "",
        suffix: "",
        integerLimit: 3,
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
    }),
    cpf: '###.###.###-##',
    cnpj: '##.###.###/####-##',
    zipCode: '#####-###',
    phone: '(##) #####-####'
};

export default {
    install(Vue) {
        Vue.prototype.$rules = rules;
        Vue.prototype.$masks = masks;
    }
};