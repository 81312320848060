import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#202F45', //'#D33F49',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                success: '#4CAF50',
                error: '#b71c1c',
                warning: '#ffcc00'
            },
        },options: {
            customProperties: true
        },
    },
});
