import Vue from 'vue';

Vue.filter('phone', (value) => {
    if (value) {
        const cleaned = ('' + value).replace(/\D/g, '');
        let match;

        switch (value.length) {
            case 8:
                match = cleaned.match(/^(\d{4})(\d{4})$/);

                if (match) {
                    return `${match[1]}-${match[2]}`;
                }
                break;
            case 9:
                match = cleaned.match(/^(\d{5})(\d{4})$/);

                if (match) {
                    return `${match[1]}-${match[2]}`;
                }
                break;
            case 11:
                match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

                if (match) {
                    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
                }
                break;
        }
    }

    return value;
});